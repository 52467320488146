jQuery(document).ready(function ($) {
  const $body = $("body");
  const arrow_src = `${template_dir_uri}/library/images/ui/fa-icons/angle-left-solid-white.svg`; // eslint-disable-line no-undef

  /* TEST AREA */
  // Select all slides
  const slides = document.querySelectorAll(".slide");
  console.log(`slides: ${slides.length}`);

  // loop through slides and set each slides translateX
  slides.forEach((slide, indx) => {
    slide.style.transform = `translateX(${indx * 100}%)`;
  });

  // select next slide button
  const nextSlide = document.querySelector(".btn-next");

  const $dotsWrapper = $body.find('.dots-wrapper');
  let dotsMarkup = '';
  for (let i = 0; i < slides.length; i++) {
    if(i===0) {
      dotsMarkup += '<div class="dot active"></div>';
    } else {
      dotsMarkup += '<div class="dot"></div>';
    }
  }
  $dotsWrapper.html(dotsMarkup);
  let $dots = $body.find('.dots-wrapper .dot');

  function updateDots(index) {
    $dots.removeClass("active");
    $dotsWrapper.children().eq(index).addClass("active");
  }

  // current slide counter
  let curSlide = 0;

  // maximum number of slides
  let maxSlide = slides.length - 1;

  // add event listener and navigation functionality
  nextSlide.addEventListener("click", function () {
    // check if current slide is the last and reset current slide
    if (curSlide === maxSlide) {
      curSlide = 0;
    } else {
      curSlide++;
    }
    updateDots(curSlide);
    //   move slide by -100%
    slides.forEach((slide, indx) => {
      slide.style.transform = `translateX(${100 * (indx - curSlide)}%)`;
    });
    stopVimeoVideos();
  });

  // select next slide button
  const prevSlide = document.querySelector(".btn-prev");

  // add event listener and navigation functionality
  prevSlide.addEventListener("click", function () {
    // check if current slide is the first and reset current slide to last
    if (curSlide === 0) {
      curSlide = maxSlide;
    } else {
      curSlide--;
    }
    updateDots(curSlide);

    //   move slide by 100%
    slides.forEach((slide, indx) => {
      slide.style.transform = `translateX(${100 * (indx - curSlide)}%)`;
    });
    stopVimeoVideos();
  });

  /* END - TEST AREA */

  function stopVimeoVideos() {
    $body.find(".slider-wrapper iframe").each(function (elem) {
      // stop Vimeo videos
      this.contentWindow.postMessage(
        '{ "method": "pause" }',
        "https://player.vimeo.com"
      );
    });
  }

  /** ===========================================
	* Window resize handler for Vimeo slider
	============================================= */
  function vimeoSliderResize() {
    // 1. get width of #featured-projects
    const section_w = $("#featured-projects").width();

    // 2. calculate height by 16:9 aspect ratio
    const frame_h = (section_w * 9) / 16;

    // 3. set height of slide-item to calculated height
    const $slides = $(".slide-item");
    $slides.each(function (index) {
      $(this).css("height", `${frame_h}px`);
    });
    $body.find(".slider-wrapper").css("height", `${frame_h}px`);
    $body.find(".slider").css("height", `${frame_h}px`);
    $body.find(".slide").css("height", `${frame_h}px`);
  }
  $(window).resize(vimeoSliderResize);
  vimeoSliderResize();

  // setTimeout(function () {
  //   console.log("refreshing slick");
  // 	$(".featured-project-slick").not(".slick-initialized").slick(slickOptions);
  // }, 8000);
});
